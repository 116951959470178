.begin {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  padding-top: 24px;
}

.div-begin {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 75%;
  padding-top: 24px;
}

.hero1 {
  height: auto;
  padding-top: 24px;
  padding-bottom: 12px;
  border-radius: 50%;
  width: 75%;
}

@media (max-width: 750px) {
  .div-begin {
    width: 95%;
  }
  .hero1 {
    width: 95%;
  }
}