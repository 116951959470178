footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  background-color: #eea2a5;
  height: 120px;
}

footer, a {
  color:white;
  text-decoration: none;
}

.footer-title {
  text-align: center;
  font-size: 24px;
  padding: 6px;
}

.dev-title {
  font-size: 18px;
}

.footer-title span {
  color:  #7c726b;;
}

.dev-title span {
  color: #7c726b;
}

@media (max-width: 600px) {
  .footer-title {
    font-size: 18px;
  }

  .dev-title {
    font-size: 14px;
  }

  footer {
    height: 140px;
  }
}
