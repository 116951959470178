.form-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 24px;
}

.div-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 75%;
}

.div-form p {
  text-align: center;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 24px;
  height: 1200px;
}

@media (max-width: 750px) {
  .form {
    height: 1150px;
  }
  .div-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 95%;
  }
}

@media (max-width: 650px) {
  .form {
    height: 1200px;
  }
}

@media (max-width: 530px) {
  .form {
    height: 1250px;
  }
}

@media (max-width: 465px) {
  .form {
    height: 1300px;
  }
}

@media (max-width: 430px) {
  .form {
    height: 1350px;
  } 
}


@media (max-width: 380px) {
  .form {
    height: 1300px;
  }
  .div-form {
    width: 100%;
  }  
}

@media (max-width: 360px) {
  .form {
    height: 1350px;
  }
}

@media (max-width: 320px) {
  .form {
    height: 1400px;
  }
}