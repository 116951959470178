.PopoverTrigger {
  all: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 0 10px;
  font-size: 10px;
  line-height: .5;
  font-weight: 500;
  height: 100%;
  background-color: #eea2a5;
  color:white;
  box-shadow: 0 2px 10px var(--blackA7);
}

.PopoverTrigger:hover {
  background-color: #fd777b;
}

.PopoverTrigger:focus {
  box-shadow: 0 0 0 1px #fd777b;
}

.PopoverContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 4px;
  padding: 20px;
  width: 168px;
  font-size: 18px;
  line-height: 1;
  color: #eea2a5;
  background-color: #eea2a5;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
}

.PopoverContent:focus {
  outline: none;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px,
    0 0 0 2px #eddacd;
}

.PopoverArrow {
  fill: #eea2a5;
}
