.header-desktop {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #eea2a5;
  color: white;
  font-weight: 700;
}

.header-mobile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #eea2a5;
  background-size: contain;
  width: 100%;
}

.header-img {
  max-width: 100%;
  height: auto;
}

.nav-desktop {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: #eea2a5;
  width: 75%;
  height: 36px;
}

.nav-menu-desktop a, .nav-menu-mobile a {
  color: white;
  text-decoration: none;
}

.div-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.nav-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #eea2a5;
  width: 75%;
}

@media (max-width: 750px) {
  .header-mobile {
    overflow: hidden;
    height: 25vh;
  }

 .header-img {
    max-width: 125%;
    height: auto;
  }
}
